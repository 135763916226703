
import { ContentLoader } from 'vue-content-loader';
import {defineComponent, reactive, toRefs, ref, onMounted, watch} from 'vue';
import {useRouter, useRoute} from 'vue-router';
import useAlert from "@/composables/Alert";
import {ConcessionariaModel} from "@/models/ConcessionariaModel.ts";
import {getOficinaDigitalPrecificar, getOficinaDigitalAvancarManual} from "@/services/OficinaDigital.ts";
import Swal from "sweetalert2/dist/sweetalert2.js";
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import LogEtapasModal from "@/components/modals/OficinaDigitalModals/LogEtapasModal.vue";

export default {
  name: "avancar",
  components: {
    MessageModal,
    LogEtapasModal,
    ContentLoader
  },

  setup() {
    let animate = ref(true)
    const {showConfirmAlert, showTimeAlert} = useAlert();
    const emptyArray: Array<any> = [];
    const dados:any = reactive({
      info: {},
      preos: {},
      concessionaria: {},
      itensOrcamento: emptyArray,
      servicos: {
        itemReparo: emptyArray,
        itemAtencao: emptyArray,
        preventivo: emptyArray
      }
    });
    const codConcessionaria = ref(0);
    const checklist = ref(0);
    const codCheckList = ref(0);
    let loadingButton = ref(false);
    let loader = ref(false);
    const router = useRouter();
    const route = useRoute();
    const textOrcamento = ref("");
    const dataLogEtapas = ref({});

    onMounted(async () => {
      animate.value = true;
      codConcessionaria.value = Number(route.params.codConcessionaria);
      checklist.value = Number(route.params.checklist);
      codCheckList.value = Number(route.params.codCheckList);

      dataLogEtapas.value = { 
        codChecklist: codCheckList.value,
        checklist: checklist.value,
        codConcessionaria: codConcessionaria.value
      }

      await getInformacoes();
       animate.value = false;
    });

    function goTo(verificador) {
      let url = "/#/";
      if (verificador == "voltar") {
        url = "/oficina-digital/gerenciar";
      } else if (verificador == "checklist") {
        url = dados.info['urlchecklist'];
        window.open(url);
        return
      }
      router.push(url)
    };

    function verFoto(item) {
      if (item.link) {
        Swal.fire({
          html: `<p style="margin: 0; padding: 0; white-space: nowrap">${item.item}</p>`,
          imageUrl: item.link,
          imageWidth: 550,
          imageHeight: 550,
          showCloseButton: true,
          showConfirmButton: false,
          customClass: 'swal-height2'
        });
      }
    }

    function verVideo(item) {
      if (item.link) {
        Swal.fire({
          width: '600px',
          html: `<p>${item.item}</p>
                  <video height="200" width="100%" controls>
                     <source src="${item.link}" type="video/mp4">
                  </video>`,
          showCloseButton: true,
          showConfirmButton: false,
          customClass: 'swal-height'
        })
      }
    }

    async function getInformacoes() {
      let response = await getOficinaDigitalPrecificar(codCheckList.value, codConcessionaria.value, checklist.value); //puxando pela precificar pois contem os dados necessarios
      dados.info = response['dados'];
      dados.preos = response['preos'][0]; //contem esse [0] pois existe um array vindo do backend
      dados.itensOrcamento = response['itensOrcamento'].map(itemOrcamento => {
        return {
          ...itemOrcamento,
          situacaoInicial: itemOrcamento.situacao,
          errors: []
        }
      }).sort((itemOrcamentoA, itemOrcamentoB) => {
        if (itemOrcamentoA.situacao != "aprovado" && itemOrcamentoB.situacao == "aprovado") {
          return -1;
        }
        if (itemOrcamentoB.situacao != "aprovado" && itemOrcamentoA.situacao == "aprovado") {
          return 1;
        }

        if (itemOrcamentoA.situacao == "" && itemOrcamentoB.situacao != "") {
          return -1;
        }
        if (itemOrcamentoB.situacao != "" && itemOrcamentoA.situacao == "") {
          return 1;
        }
        // a deve ser igual a b
        return 0;
      });
      dados.concessionaria = response['concessionaria'];
      geraTextOrcamento();
      getServicos();
    };

    function geraTextOrcamento() {
      textOrcamento.value = "";
      if(dados.preos){
        const parcelamento = dados.itensOrcamento && dados.itensOrcamento[0].parcelaInicial ? parseInt(dados.itensOrcamento[0].parcelaInicial) : (dados.preos.parcelamento || 1);
        const pacote = dados.preos.pacote;
        let valorTotal = dados.itensOrcamento && dados.itensOrcamento[0].orcamentoInicial ? dados.itensOrcamento[0].orcamentoInicial : (dados.preos.valorTotalPreOS || "0");
        valorTotal = parseFloat(valorTotal.replace('.', '').replace(',', '.'));
        const valorParcela = valorTotal / parcelamento;
        
        textOrcamento.value = `Pacote ${ pacote }, em ${ parcelamento } x ${ valorParcela.toLocaleString("pt-br", {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'BRL'}) }, totalizando ${ valorTotal.toLocaleString("pt-br", {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'BRL'}) }`;
      }
    };

    function getServicos() {
      let itemReparo: any = [];
      let itemAtencao: any = [];
      let preventivo: any = [];
      dados.itensOrcamento.forEach(item => {
        if (item.prioridade == 3) {
          itemReparo.push({
            item: item.item,
            codFoto: item.codFoto, 
            codVideo: item.codVideo,
            link: item.link
          });
        } else if (item.prioridade == 2) {
          itemAtencao.push({
            item: item.item,
            codFoto: item.codFoto, 
            codVideo: item.codVideo,
            link: item.link
          });
        } else if (item.prioridade == 1) {
          preventivo.push({
            item: item.item,
            codFoto: item.codFoto, 
            codVideo: item.codVideo,
            link: item.link
          });
        }
      });
      dados.servicos.itemReparo = itemReparo;
      dados.servicos.itemAtencao = itemAtencao;
      dados.servicos.preventivo = preventivo;
    };

    function mascaraTelefone(telefone) {
      let arrayMascaras = " ";
      if (telefone) {
        let replaceTelefone = telefone.replaceAll(" ", ""); //retira espacos do telefone ex: (31)33333333(31)222222222
        replaceTelefone = replaceTelefone.replaceAll(")", ""); //retira parenteses ex: (3133333333(3122222222
        let splitTelefone = replaceTelefone.split("("); //Cria array separando pelo parenteses ex: ["","3133333333","3122222222"]
        let arrayTelefones = splitTelefone.filter((a) => a); //Cria filtro para retirar null e vazio ex: ["3133333333","3122222222"]
        arrayTelefones.forEach((value, index) => {  //percorre o array de telefones
          let mascara = "(" + value.substring(0, 2) + ") ****-" + value.substring(value.length - 4, value.length);
          arrayMascaras = arrayMascaras + " " + mascara;
        });

      }
      return arrayMascaras;
    };

    function validaForm(itensOrcamento){
      itensOrcamento.forEach(item => {
        item.errors = [];
        if (item.situacao) {
          if ((item.situacao == 2 && item.motivoReprovacao) || (item.situacao == 1)) {
            return;
          } else {
            item.errors.push(`Selecione algum motivo para o serviço ${item.item}`);
          }
        } else {
          item.errors.push(`Aprove ou reprove o serviço ${item.item}`);
        }
      });

      return !itensOrcamento.some(item => item.errors.length > 0);
    }

    async function save() {
      const formIsValid = validaForm(dados.itensOrcamento);
      if(!formIsValid){
        const refIndex = dados.itensOrcamento.findIndex(item => item.errors.length > 0);
        document.getElementById(`card-avancar-${refIndex}`)?.scrollIntoView({block: "center", inline: "nearest"});

        showTimeAlert("Preencha os campos necessários para prosseguir", 'error');
        return;
      }

      const servicos = dados.itensOrcamento.map(item => {
        return {
          codOrcamento: item.codOrcamento,
          situacao: item.situacao ? Number(item.situacao) : 0,
          motivoReprovacao: Number(item.situacao) == 2 ? item.motivoReprovacao : null,
        }
      });

      //save
      const postData = {
        serviços: servicos
      }

      Swal.fire({
        title: "Deseja avançar o orçamento?",
        text: "A ação não poderá ser desfeita",
        position: 'center',
        showConfirmButton: true,
        reverseButtons: true,
        allowOutsideClick: true,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-flex background_btn_1_opct text-white",
          cancelButton: "btn btn-light me-3",
        },
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          loadingButton.value = true;
          getOficinaDigitalAvancarManual(postData, codCheckList.value, codConcessionaria.value, checklist.value).then((value) => {
            loadingButton.value = false;
            if(!value.error){
              showTimeAlert(value.message);
            }else{
              showTimeAlert(value.message,'error');
            }
            goTo("voltar");
          })
          .catch((error) => {
            showTimeAlert(error, 'error');
          });
        }
      });

    };

    function trataNome(nome){
			if(!nome || nome == "undefined" || nome == undefined){
				return "";
			}
			const arrayNomes = nome.split(" ");

			let nomeTratado = `${arrayNomes[0]}`;
			if(arrayNomes[1]){
				nomeTratado += ` ${arrayNomes[1].slice(0, 1)}.`;
			}

			return nomeTratado;
    };	

    return {
      ...toRefs(dados),
      trataNome,
      save,
      getServicos,
      loadingButton,
      mascaraTelefone,
      goTo,
      loader,
      verVideo,
      verFoto,
      textOrcamento,
      codCheckList,
      codConcessionaria,
      checklist,
      animate,
      dataLogEtapas
    }
  }
};
